import React, { useState, useEffect } from "react";
import { Container } from 'react-bootstrap';
import { Link } from "@StarberryUtils";
import Slider from 'react-slick';
import ContactCardServices from "../Home/ContactCard/ContactCardServices"
import _ from "lodash"
import "../FeaturedNewHome/FeaturedNewHome.scss"

const PropertiesServices = (props) => {

  const featuredetails = props?.details

  console.log("featuredetails", featuredetails)

  const settings = {
    dots: true,
    speed: 800,
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 5000,
    arrows: false,
    mobileFirst: true,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true
        }
      },
    ]
  }


  useEffect(()=>{
    handleResize();
    window.addEventListener('resize', handleResize);
    function handleResize() {
      setTimeout(() => {
          if( 768<=window.innerWidth ) {
              const collection = document.getElementsByClassName("propertyServicesText");
              var maxHeight = 0;
              for (var i=0; i < collection.length; i++) {
                  if(collection[i].getAttribute("style")) {
                      collection[i].style.removeProperty("height");
                  }
                  maxHeight = Math.max(maxHeight, collection[i].offsetHeight);
              }
              for (var i=0; i < collection.length; i++) {
                collection[i].style.height = maxHeight+'px';
              }
          }
      }, 150);
    }
  },[]);


  return (   
    featuredetails &&
    <div className={`featured-new-projects-section market-analysis project-analysis ${props?.mod_class?props?.mod_class:''}`}>
      <Container className="featured-new-container">
        <h2 className="new-project-title text-center">{featuredetails?.service_title}</h2>
        {featuredetails?.content &&
               <p className="tab-desc">{featuredetails?.content}</p>
        }
        <div className="featured-new-projects-slider-section">

          <Slider {...settings} >
            {
              !_.isEmpty(featuredetails?.property_serviceslist) && featuredetails?.property_serviceslist?.map((item, index) => {
                return ( 
                  <ContactCardServices
                    cardImg={item?.service_image}
                    cardTitle={item?.servicetitle}
                    cardContents={item?.service_contents}
                    imagetransforms={item.ggfx_results}
                    ggfxNew={true} 
                    cardUrl={item.button_url}
                    cardButtonName={item.button_name}
                  />
                )
              })
            }
          </Slider>
        </div>       
      </Container>
    </div>
  )
}

export default PropertiesServices